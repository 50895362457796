<template>
  <div>
    <v-row class="pb-0 mt-5 ml-2 mr-2" order="1">
      <Grid :tamanho="12">
        <v-card elevation="0">
          <v-card-title class="align-start">
            <span>Pergunta x Pergunta</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <Grid :tamanho="6">
                <v-autocomplete
                  dense
                  outlined
                  v-model="perguntaExterna"
                  :items="externasData"
                  label="Perguntas Externas"
                  item-value="id_pco_perguntas"
                  item-text="descricao"
                  return-object
                  :loading="
                    externasData && externasData.length == 0 ? true : false
                  "
                  @blur="clearOptions()"
                >
                </v-autocomplete>
              </Grid>
             
              <Grid :tamanho="6">
                <v-autocomplete
                  dense
                  outlined
                  v-model="respostaExterna"
                  :items="perguntaExterna.opcoes"
                  label="Opções"
                  item-value="id"
                  item-text="descricao"
                  multiple
                  :disabled="
                    perguntaExterna && perguntaExterna.id_pergunta > 0
                      ? false
                      : true
                  "
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.descricao }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption"
                      >(+{{
                        perguntaExterna.opcoes &&
                        perguntaExterna.opcoes.length - 1
                      }}
                      outros)</span
                    >
                  </template>
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @click="toggleSelect()"
                    >
                      <v-list-item-action>
                        <v-icon
                          :color="
                            perguntaExterna.opcoes && perguntaExterna.opcoes > 0
                              ? 'indigo darken-4'
                              : ''
                          "
                          >{{ resolvedIcon() }}</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{
                            (perguntaExterna.opcoes && perguntaExterna.opcoes.length) != respostaExterna.length
                              ? "Selecionar todos"
                              : "Desmarcar todos"
                          }}</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-autocomplete>
              </Grid>              
              <Grid :tamanho="6">
                <v-autocomplete
                  dense
                  outlined
                  v-model="grupoFator"
                  :items="gruposSiglas"
                  label="Fatores internos"
                  item-value="sigla"
                  item-text="nome"
                  return-object
                  :loading="externasData.length == 0 ? true : false"
                >
                </v-autocomplete>
              </Grid>
              <Grid :tamanho="6" v-if="grupoFator">
                <v-autocomplete
                  dense
                  outlined
                  v-model="perguntaInterna"
                  :items="resolvedQuestion(grupoFator)"
                  label="Perguntas internas"
                  item-value="id_pergunta"
                  item-text="descricao"
                  :disabled="grupoFator.sigla != '' ? false : true"
                >
                </v-autocomplete>
              </Grid>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex flex-row-reverse">
            <v-btn color="primary" @click="generateGrafic()" :loading="loadingBtnAction">
              Gerar gráfico
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card elevation="0" class="mb-4" outlined>
          <v-card-title>Gráfico</v-card-title>
          <v-card-subtitle>{{ perguntaExterna.descricao }} x {{ resolvedQuestionDescription() }}</v-card-subtitle>
          <v-card-text v-if="validadDataGrafic == true">
            <apex-chart-expense-ratio
              :labels="generateLabels()"
              :series="generateSeries()"
              :type="'pie'"
            ></apex-chart-expense-ratio>
          </v-card-text>
        </v-card>
      </Grid>
    </v-row>
  </div>
</template>

<script>

import ApexChartExpenseRatio from "@/components/ApexChartExpenseRatio.vue";
import store from "@/store";
import { computed, ref, onMounted } from "@vue/composition-api";
import themeConfig from "@themeConfig";
import Grid from "@/components/Grid.vue";
import { useRouter } from "@core/utils";

export default {
  components: {
    ApexChartExpenseRatio,
    Grid,
  },
  setup() {
    const $themeColors = themeConfig.themes.light;
    const $chartColors = themeConfig.chartColors;
    const perguntaExterna = ref([]);
    const respostaExterna = ref([]);
    const respostaData = ref([]);
    const perguntaInterna = ref();
    const dataGrafico = ref([]);
    const { router } = useRouter();
    const validadDataGrafic = ref(false);
    const grupoFator = ref({
      sigla: "",
      nome: "",
    });
    const externasData = computed(
      () => store.getters["pesquisas/getExternasPainel"]
    );
    const internasData = computed(
      () => store.getters["pesquisas/getGruposPainel"]
    );
    const gruposSiglas = computed(() => {
      let gruposSiglas = [];
      if (internasData && internasData.value.length == 0) return [];

      Object.keys(internasData.value).forEach((el) => {
        gruposSiglas.push({
          sigla: el,
          nome: internasData.value[el].nome_grupo,
        });
      });

      return gruposSiglas;
    });
    const resolvedQuestion = (fator) => {
      let itemsInternasData = [];
      if (fator.sigla != "") {
        Object.keys(internasData.value[fator.sigla].perguntas).forEach((el) => {
          itemsInternasData.push({
            id_pergunta: el,
            descricao: internasData.value[fator.sigla].perguntas[el].descricao,
          });
        });
      }
      // console.log(itemsInternasData)
      return itemsInternasData;
    };
    const dataGraficoExtenasInterna = computed(
      () => store.getters["pesquisas/getDadosPesquisaExternaInternas"]
    );
    const loadingBtnAction = ref(false)
    function generateGrafic() {
      validadDataGrafic.value = false;
      loadingBtnAction.value = true;
      let data = {
        externa_id: perguntaExterna.value.id_pco_perguntas,
        externa_opcoes: respostaExterna.value,
        interna_id: parseInt(perguntaInterna.value),
        uuid: router.currentRoute.params.uuid,
        sigla: grupoFator.value.sigla
      };

      store.dispatch("pesquisas/buscarDadosGraficoExternaAbertas", data).then(() => {
        validadDataGrafic.value = (dataGraficoExtenasInterna.value != undefined) ? true : false
        dataGrafico.value = (dataGraficoExtenasInterna.value != undefined) ? dataGraficoExtenasInterna.value : [];
      })
      .finally(() => {
        loadingBtnAction.value = false;
      });
    }
    function clearOptions()
    {
      respostaExterna.value = []
    }
    function resolvedIcon() {      
      if (perguntaExterna.value.opcoes && respostaExterna.value)
      {
        if (perguntaExterna.value.opcoes.length == respostaExterna.value.length) return "mdi-close-box";
        if (respostaExterna.value.length > 0) return "mdi-minus-box";    
      }
      return "mdi-checkbox-blank-outline";    
    }
    function toggleSelect() {
      respostaExterna.value.length == perguntaExterna.value.opcoes.length
        ? (respostaExterna.value = [])
        : (respostaExterna.value = perguntaExterna.value.opcoes.map((t) => t.id));
    }
    const generateLabels = () => {
      let labels = [];
      Object.keys(dataGrafico.value).forEach((el, i) => {                
        if (el == 'DT') return labels[i] = "Discordo Totalmente";
        if (el == 'DP') return labels[i] = "Discordo Parcialmente";
        if (el == 'CT') return labels[i] = "Concordo Totalmente";
        if (el == 'CP') return labels[i] = "Concordo Parcialmente";
      });
      return labels;
    }
    const generateSeries = () => {
      let total = [];
      Object.keys(dataGrafico.value).forEach((el, i) => {                
        if (el == 'DT') return total[i] = dataGrafico.value[el];
        if (el == 'DP') return total[i] = dataGrafico.value[el];
        if (el == 'CT') return total[i] = dataGrafico.value[el];
        if (el == 'CP') return total[i] = dataGrafico.value[el];
      });
      
      return total;
    }
    const resolvedQuestionDescription = () => {
      if(grupoFator.value.sigla != "" && perguntaInterna.value)
        return internasData.value[grupoFator.value.sigla].perguntas[perguntaInterna.value].descricao
    }
    return {
      perguntaExterna,
      respostaExterna,
      perguntaInterna,
      internasData,
      externasData,
      respostaData,
      gruposSiglas,
      grupoFator,
      loadingBtnAction,
      dataGrafico,

      resolvedQuestion,
      generateGrafic,
      clearOptions,
      resolvedIcon,
      toggleSelect,
      generateLabels,
      generateSeries,
      resolvedQuestionDescription,
      validadDataGrafic
    };
  },
};
</script>

<style>
</style>