<template>
  <vue-apex-charts
    :type="type"
    height="350"
    :options="graficosDonutOpcoes.chartOptions"
    :series="graficosDonutOpcoes.series"
  /> 
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import themeConfig from "@themeConfig";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    labels: {
      type: Array,
      required: false,
    },
    series: {
      type: Array,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const $themeColors = themeConfig.themes.light;
    const $chartColors = themeConfig.chartColors;
    const graficosDonutOpcoes = {
      series: props.series,
      chartOptions: {
        chart: {
          toolbar: {
            show: true,
          },
        },
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '14px',
        fontFamily: '"Inter", sans-serif',
      },
        colors: [
          $chartColors.series1,
          $chartColors.series2,
          $chartColors.series3,
          $chartColors.series4,
          $chartColors.series5,
          $chartColors.series6,
          $chartColors.series7,
          $chartColors.series8,
          $chartColors.series9,
          $chartColors.series10,
          $chartColors.series11,
          $chartColors.series12,
          $chartColors.series13,
          $chartColors.series14,
          $chartColors.series15,
          $chartColors.series16,
          $chartColors.series17,
          $chartColors.series18,
          $chartColors.series19,
          $chartColors.series20
        ],
        dataLabels: {
          enabled: true,
          formatter(val) {
            // eslint-disable-next-line radix
            return val ? val.toFixed(1) + '%' : ''
          },
        },
        labels: props.labels,
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 380,
              },
              legend: {
                position: "bottom",
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: 320,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: "0.5rem",
                      },
                      value: {
                        fontSize: "0.5rem",
                      },
                      total: {
                        fontSize: "0.5rem",
                      },
                    },
                  },
                },
              },
              legend: {
                show: true,
              },
            },
          },
        ],
      },
    };

    return {
      graficosDonutOpcoes,
    };
  },
};
</script>
