import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{staticClass:"pb-0 mt-5 ml-2 mr-2",attrs:{"order":"1"}},[_c('Grid',{attrs:{"tamanho":12}},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardTitle,{staticClass:"align-start"},[_c('span',[_vm._v("Pergunta x Pergunta")])]),_c(VCardText,[_c(VRow,[_c('Grid',{attrs:{"tamanho":6}},[_c(VAutocomplete,{attrs:{"dense":"","outlined":"","items":_vm.externasData,"label":"Perguntas Externas","item-value":"id_pco_perguntas","item-text":"descricao","return-object":"","loading":_vm.externasData && _vm.externasData.length == 0 ? true : false},on:{"blur":function($event){return _vm.clearOptions()}},model:{value:(_vm.perguntaExterna),callback:function ($$v) {_vm.perguntaExterna=$$v},expression:"perguntaExterna"}})],1),_c('Grid',{attrs:{"tamanho":6}},[_c(VAutocomplete,{attrs:{"dense":"","outlined":"","items":_vm.perguntaExterna.opcoes,"label":"Opções","item-value":"id","item-text":"descricao","multiple":"","disabled":_vm.perguntaExterna && _vm.perguntaExterna.id_pergunta > 0
                    ? false
                    : true},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var item = ref.item;
                    var index = ref.index;
return [(index === 0)?_c(VChip,[_c('span',[_vm._v(_vm._s(item.descricao))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.perguntaExterna.opcoes && _vm.perguntaExterna.opcoes.length - 1)+" outros)")]):_vm._e()]}},{key:"prepend-item",fn:function(){return [_c(VListItem,{attrs:{"ripple":""},on:{"click":function($event){return _vm.toggleSelect()}}},[_c(VListItemAction,[_c(VIcon,{attrs:{"color":_vm.perguntaExterna.opcoes && _vm.perguntaExterna.opcoes > 0
                            ? 'indigo darken-4'
                            : ''}},[_vm._v(_vm._s(_vm.resolvedIcon()))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s((_vm.perguntaExterna.opcoes && _vm.perguntaExterna.opcoes.length) != _vm.respostaExterna.length ? "Selecionar todos" : "Desmarcar todos"))])],1)],1),_c(VDivider,{staticClass:"mt-2"})]},proxy:true}]),model:{value:(_vm.respostaExterna),callback:function ($$v) {_vm.respostaExterna=$$v},expression:"respostaExterna"}})],1),_c('Grid',{attrs:{"tamanho":6}},[_c(VAutocomplete,{attrs:{"dense":"","outlined":"","items":_vm.gruposSiglas,"label":"Fatores internos","item-value":"sigla","item-text":"nome","return-object":"","loading":_vm.externasData.length == 0 ? true : false},model:{value:(_vm.grupoFator),callback:function ($$v) {_vm.grupoFator=$$v},expression:"grupoFator"}})],1),(_vm.grupoFator)?_c('Grid',{attrs:{"tamanho":6}},[_c(VAutocomplete,{attrs:{"dense":"","outlined":"","items":_vm.resolvedQuestion(_vm.grupoFator),"label":"Perguntas internas","item-value":"id_pergunta","item-text":"descricao","disabled":_vm.grupoFator.sigla != '' ? false : true},model:{value:(_vm.perguntaInterna),callback:function ($$v) {_vm.perguntaInterna=$$v},expression:"perguntaInterna"}})],1):_vm._e()],1)],1),_c(VCardActions,{staticClass:"d-flex flex-row-reverse"},[_c(VBtn,{attrs:{"color":"primary","loading":_vm.loadingBtnAction},on:{"click":function($event){return _vm.generateGrafic()}}},[_vm._v(" Gerar gráfico ")])],1)],1),_c(VCard,{staticClass:"mb-4",attrs:{"elevation":"0","outlined":""}},[_c(VCardTitle,[_vm._v("Gráfico")]),_c(VCardSubtitle,[_vm._v(_vm._s(_vm.perguntaExterna.descricao)+" x "+_vm._s(_vm.resolvedQuestionDescription()))]),(_vm.validadDataGrafic == true)?_c(VCardText,[_c('apex-chart-expense-ratio',{attrs:{"labels":_vm.generateLabels(),"series":_vm.generateSeries(),"type":'pie'}})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }